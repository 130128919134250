@font-face {
	font-family: "Material Icons";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
	format("woff2");
}

.mdl-demo .dropdown-trigger > span:after {
	font-size: 12px;
	color: #555;
}

.mdl-demo > .dropdown > .dropdown-content {
	z-index: 5;
}

.mdl-demo .toggle {
	font: normal normal normal 18px/1 "Material Icons";
	color: #555;
	white-space: pre;
	margin-right: 4px;
}

.mdl-demo .toggle.collapsed::after {
	cursor: pointer;
	content: "\E5CF";
	vertical-align: middle;
}

.mdl-demo .toggle.expanded::after {
	cursor: pointer;
	content: "\E5CE";
	vertical-align: middle;
}

/* checkbox styles */
.mdl-demo .checkbox-item {
	position: relative;
	width: 1rem;
	height: 1rem;
	margin-right: 0.75rem;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: 0;
	vertical-align: middle;
}

.mdl-demo .checkbox-item:before,
.node.partial .checkbox-item:indeterminate:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	border: 2px solid #aaa;
	transition: all 0.3s ease-in-out;
}

.mdl-demo .checkbox-item:checked:before{
	height: 50%;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	border-top-style: none;
	border-right-style: none;
	border-color: #2196f3;
}

.node.partial .checkbox-item:indeterminate:before {
	border-color: #2196f3;
	border-top-style: none;
	border-right-style: none;
	border-left-style: none;
	transform: translateY(-8px);
}

/* ====== Custom Styles  ====== */

.react-dropdown-tree-select .dropdown {
	width: 100%;
	background: #fff;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
	display: block;
	outline: none;
	border-radius: 4px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger:hover {
	border-color: #111;
}

.tag {
	font-size: 16px;
	padding: 8px 4px 8px 8px;
	border-radius: 4px;
}

.tag-remove {
	vertical-align: middle;
	font-size: 80%;
	position: relative;
	bottom: 1px;
	color: #3755D7;
}

.tag-item .search {
	font-size: 16px;
	padding: 18px 14px;
}

.mdl-error-wrap .react-dropdown-tree-select .dropdown .dropdown-trigger {
	border-color: #f44336;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger:focus {
	box-shadow: inset 0 0 0 1px #3755D7;
	border-color: #3755D7;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
	content: '';
	width: 0;
	height: 0;
	display: inline-block;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #777;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
	content: '';
	width: 0;
	height: 0;
	display: inline-block;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #777;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
	max-height: 400px;
	overflow: auto;
	width: 100%;
}

.mdl-demo .toggle {
	outline: none;
}

.mdl-demo .checkbox-item {
	vertical-align: sub;
	width: 18px;
	height: 18px;
}

.mdl-demo .checkbox-item:before {
	border-radius: 2px;
	border-color: #777;
}

.node {
	margin-left: 4px;
}

.mdl-demo .checkbox-item:checked:before {
	border-color: #3755D7;
}

.node-label {
	font-size: 16px;
}

.mdl-error-message {
	color: #f44336;
	font-size: 12px;
	margin: 3px 14px 0;
}

.no-matches {
	font-size: 16px;
	margin: 8px;
	color: #888;
}